<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <ValidationObserver
          ref="observer"
          tag="form"
          v-slot="{ valid, validate }"
        >
          <v-form
            ref="form1"
            @submit.prevent="validate().then(enterTransaction)"
          >
            <v-scroll-y-transition>
              <v-card flat>
                <v-card-title primary-title>
                  {{ getters_student_details.index_number_title_name }}
                </v-card-title>
                <v-card-subtitle>
                  Registration for
                  <b>{{
                    `Level ${regData.level.level} ${regData.semester.semester}`
                  }}</b>
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Late Registration Fee"
                        rules="required|min_value:0"
                      >
                        <v-text-field
                          :prepend-inner-icon="'₵'"
                          :error-messages="errors"
                          outlined
                          hide-details="auto"
                          class="rounded-0"
                          name="name"
                          v-model.number="fee"
                          label="Late Registration Fee"
                          id="id"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Amount"
                        rules="required|numeric|confirmed:confirmation"
                      >
                        <v-text-field
                          outlined
                          :prepend-inner-icon="'₵'"
                          :error-messages="errors"
                          type="number"
                          class="rounded-0"
                          hide-details="auto"
                          v-model.number="amount"
                          name="name"
                          label="Fees"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Amount Paid"
                        vid="confirmation"
                      >
                        <v-text-field
                          :prepend-inner-icon="'₵'"
                          :error-messages="errors"
                          outlined
                          type="number"
                          class="rounded-0"
                          label="Re-enter Amount Paid"
                          v-model.number="confirmation"
                          v-model.lazy="confirmation"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Payment Description"
                        rules="required"
                      >
                        <v-text-field
                          outlined
                          hide-details="auto"
                          name="name"
                          class="rounded-0"
                          v-model="description"
                          value="Fees"
                          disabled
                          :error-messages="errors"
                          label="Payment Description"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Payment Method"
                        rules="required"
                      >
                        <v-text-field
                          outlined
                          class="rounded-0"
                          hide-details="auto"
                          :error-messages="errors"
                          name="name"
                          disabled
                          v-model="method"
                          label="Payment Method"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="Receipt Number"
                        rules="required"
                      >
                        <v-text-field
                          outlined
                          hide-details="auto"
                          class="rounded-0"
                          name="name"
                          :error-messages="errors"
                          v-model="receipt"
                          label="Receipt Number"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-footer app>
                  <v-btn
                    :disabled="!valid"
                    v-if="
                      getters_abilities.includes('save_student_enrol_access')
                    "
                    type="submit"
                    color="success"
                    class="ml-2"
                  >
                    Save
                  </v-btn>
                </v-footer>
              </v-card>
            </v-scroll-y-transition>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>

    <Response class="mb-5" v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-overlay absolute :value="overlay" opacity="1">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
  import {
    defineComponent,
    getCurrentInstance,
    inject,
    provide,
    reactive,
    toRefs,
  } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    required,
    regex,
    max_value,
    max,
    numeric,
    confirmed,
    min_value,
  } from "vee-validate/dist/rules";
  extend("regex", {
    ...regex,
    message: "{_field_}  is not valid",
  });

  extend("max", {
    ...max,
    message: "Field should not exceed 100 characters",
  });

  extend("numeric", {
    ...numeric,
    message: "Not Valid",
  });

  extend("confirmed", {
    ...confirmed,
    message: "Re-enter same amount in the next field",
  });

  extend("min_value", {
    ...min_value,
    message: "Not Valid",
  });

  extend("max_value", {
    ...max_value,
    message: "Not Valid",
  });

  extend("required", {
    ...required,
    message: "{_field_} cannot be empty",
  });
  import Response from "@/components/ActionResponse/Response";
  export default defineComponent({
    components: {
      PageHeader,
      Response,
      ValidationObserver,
      ValidationProvider,
    },

    setup() {
      const vm = getCurrentInstance();
      const regData = inject("regData");
      const { signOut, lateRegistration } = useActions([
        "signOut",
        "lateRegistration",
      ]);

      const { getters_student_details, getters_abilities } = useGetters([
        "getters_student_details",
        "getters_abilities",
      ]);

      const studentForm = reactive({
        fee: 0,
        receipt: "",
        method: "Bank Receipt",
        amount: 0,
        description: "Fees",
        color: null,
        confirmation: null,
        response: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        headers: [
          { text: "Academic Calendar", value: "calendar.academic_calendar" },
          { text: "Level", value: "level.level" },
          { text: "Semester", value: "semester.semester" },
          {
            text: "Status",
            value: "status",
            sortable: false,
            align: "center",
            width: "10%",
          },
        ],
        expected_semester: "",
        registration: null,
        expected_startYear: null,
        expected_endYear: null,
        expected_level: null,
        lateReg: false,
        overlay: false,
        form1: null,
      });

      const {
        form1,
        fee,
        receipt,
        method,
        amount,
        description,
        overlay,
        color,
        response,
        msgHeader,
        msgBody,
        msgIcon,
      } = toRefs(studentForm);

      const enterTransaction = () => {
        response.value = false;
        overlay.value = true;
        const data = {
          description: description.value,
          late_registration_fee: fee.value,
          amount: amount.value,
          receipt: receipt.value,
          method: method.value,
          student_id: getters_student_details.value.id,
          level_id: regData.value.level_id,
          semester_id: regData.value.semester_id,
          calendar_id: regData.value.calendar_id,
          status_id: getters_student_details.value.student_status_id,
        };

        lateRegistration(data)
          .then(() => {
            msgHeader.value = "Success";
            msgBody.value = "Saved successfully";
            msgIcon.value = "mdi-check-circle";
            color.value = "success";
            response.value = true;
          })
          .catch((e) => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 403:
              case 401:
                msgBody.value = e.response.data.message;
                break;
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              default:
                msgBody.value =
                  "Try Again Later or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            overlay.value = false;
            form1.value.reset();
          });
      };

      provide("color", color);

      return {
        ...toRefs(studentForm),
        getters_student_details,
        enterTransaction,
        regData,
        getters_abilities,
      };
    },
  });
</script>
