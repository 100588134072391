<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">LATE </span> REGISTRATION
    </PageHeader>
    <v-row justify="center" align="center">
      <v-col cols="12" lg="8">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title class="subtitle-1">
              Search Student by Index Number
            </v-toolbar-title>

            <v-autocomplete
              @change="studentDetails"
              :loading="loadStudent"
              v-model="index_number"
              :items="studentcampusBasket"
              :search-input.sync="searchStudent"
              @keyup="debouncesearchStudent"
              hide-selected
              cache-items
              hide-details
              flat
              item-text="index_number_title_name"
              item-value="id"
              label="Index Number"
              solo-inverted
              class="mx-4"
            >
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img
                    :src="data.item.avatar_url"
                    :lazy-src="data.item.avatar_url"
                  >
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.index_number_title_name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title v-if="loadStudent">
                    <span class="mr-4">
                      <v-progress-circular
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </span>
                    Searching
                    <strong>Student...</strong>
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    No
                    <strong>Student Found...</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-toolbar>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="studentStatus">
      <v-col>
        <v-alert
          dismissible
          border="left"
          text
          transition="scale-transition"
          type="info"
          :value="true"
        >
          {{ studentStatus }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="ifstudentFound">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="getters_enrolunenrol"
          hide-default-footer
          disable-pagination
          class="elevation-1"
          item-key="id"
        >
          <template #item.status="{ item }">
            <v-btn
              color="success"
              v-if="item.status"
              :disabled="
                !getters_abilities.includes('save_student_enrol_access')
              "
              @click="
                lateReg = true;
                header(item);
              "
            >
              Register
            </v-btn>
            <span v-else>Ongoing</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-navigation-drawer
      fixed
      v-model="lateReg"
      :permanent="lateReg"
      right
      width="45%"
      class="shadow"
      clipped
      elevation-19
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="lateReg = false" icon>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <late-registration v-if="lateReg"></late-registration>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
  import { defineComponent, getCurrentInstance, provide, reactive, ref, toRefs } from "vue";
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import LateRegistration from "@/components/Finance/LateRegistration";
  export default defineComponent({
    components: {
      PageHeader,
      LateRegistration,
    },

    setup() {
      const vm = getCurrentInstance()
      const {
        getStudentCampus,
        getStudentDetails,
        signOut,
        lateRegistration,
        getCalendar,
        getEnrolledUnrolled,
        getSemester,
      } = useActions([
        "getStudentCampus",
        "getStudentDetails",
        "signOut",
        "lateRegistration",
        "getCalendar",
        "getEnrolledUnrolled",
        "getSemester",
      ]);

      const {
        getters_student_campus,
        getters_student_details,
        getters_calendar,
        getters_enrolunenrol,
        getters_semester,
        getters_abilities,
      } = useGetters([
        "getters_student_campus",
        "getters_student_details",
        "getters_calendar",
        "getters_enrolunenrol",
        "getters_semester",
        "getters_abilities",
      ]);

      const searchStudentHeader = reactive({
        delStudentDialog: false,
        loadStudent: false,
        index_number: null,
        studentcampusBasket: [],
        searchStudent: null,
        iscalendarLoaded: false,
        calendar: null,
        actionResponse: false,
        basket_level: [],
        basket_calendar: [],
        basket_semester: [],
        studentStatus: null,
      });

      const {
        basket_calendar,
        iscalendarLoaded,
        searchStudent,
        studentcampusBasket,
        loadStudent,
        index_number,
        basket_semester,
        studentStatus,
      } = toRefs(searchStudentHeader);

      let ifstudentFound = ref(false);

      let studentForm = reactive({
        fee: 0,
        receipt: "",
        method: "",
        amount: 0,
        absolute: true,
        overlay: false,
        description: "",
        student_id: index_number.value,
        color: null,
        response: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        headers: [
          { text: "ACADEMIC CALENDAR", value: "calendar.academic_calendar" },
          { text: "LEVEL", value: "level.level" },
          { text: "SEMESTER", value: "semester.semester" },
          {
            text: "STATUS",
            value: "status",
            sortable: false,
            align: "center",
            width: "10%",
          },
        ],
        expected_semester: "",
        registration: null,
        expected_startYear: null,
        expected_endYear: null,
        expected_level: null,
        lateReg: false,
        regData: {},
      });

      const {
        regData,
        fee,
        receipt,
        method,
        amount,
        description,
        overlay,
        color,
        response,
        msgHeader,
        msgBody,
        msgIcon,
        registration,
        expected_semester,
        expected_level,
      } = toRefs(studentForm);

      const studentDetails = (index_number) => {
        overlay.value = true;
        getStudentDetails(index_number)
          .then(() => {
            overlay.value = false;

            if (
              getters_student_details.value.student_status.status.toLowerCase() !==
              "in school"
            ) {
              studentStatus.value =
                "Status: " +
                getters_student_details.value.student_status.status[0].toUpperCase() +
                getters_student_details.value.student_status.status.slice(1);
              return;
            }
            studentStatus.value = null;

            getEnrolledUnrolled(index_number).then(() => {
              ifstudentFound.value = true;
              registration.value =
                getters_enrolunenrol.value[getters_enrolunenrol.value.length - 1];

              if (!registration.value) {
                expected_level.value =
                  getters_student_details.value.current_level.level;
                expected_semester.value = getters_semester.value.find((curr) => {
                  if (curr.id === 1) {
                    return curr.semester;
                  }
                });
                return;
              }
              const semester_id = registration.value.semester_id;

              expected_semester.value = getters_semester.value.find((curr) => {
                if (curr.id !== semester_id) {
                  return curr.semester;
                }
              });

              expected_level.value =
                expected_semester.value?.id === 1
                  ? registration.value?.level.level + 100
                  : (expected_level.value = registration.value?.level?.level);
            });
          })
          .catch((e) => {
            if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            }
          });
      };

      const debouncesearchStudent = debounce(() => {
        if (searchStudent.value) {
          // if (studentcampusBasket.value.length > 0) return;
          loadStudent.value = true;

          getStudentCampus(searchStudent.value)
            .then(() => {
              studentcampusBasket.value = getters_student_campus.value;
            })
            .finally(() => {
              loadStudent.value = false;
            })
            .catch((e) => {
              if (e.response.status === 423) {
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
              }
            });
        }
      });

      const enterTransaction = () => {
        response.value = false;

        let data = {
          description: description.value,
          late_registration_fee: fee.value,
          amount: amount.value,
          receipt: receipt.value,
          method: method.value,
          student_id: index_number.value,
          level: expected_level.value,
          semester_id: expected_semester.value.id,
        };

        lateRegistration(data)
          .then(() => {
            msgHeader.value = "Success";
            msgBody.value = "Saved successfully";
            msgIcon.value = "mdi-check-circle";
            color.value = "success";
            response.value = true;
          })
          .catch((e) => {
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 403:
              case 401:
                msgBody.value = e.response.data.message;
                break;
              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;
              default:
                msgBody.value =
                  "Try Again Later or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            //context.refs.form1.reset();
            overlay.value = false;
          });
      };

      const fetchCalendar = () => {
        iscalendarLoaded.value = true;
        if (getters_calendar.value.length > 0) {
          iscalendarLoaded.value = false;
          basket_calendar.value = getters_calendar.value;
          return;
        }
        getCalendar().then(() => {
          basket_calendar.value = getters_calendar.value;
          iscalendarLoaded.value = false;
        });
      };

      getSemester().then(() => {
        basket_semester.value = getters_semester.value;
      });

      const header = (item) => {
        regData.value = item;
      };

      provide("color", color);
      provide("regData", regData);

      return {
        studentDetails,
        debouncesearchStudent,
        ...toRefs(searchStudentHeader),
        ...toRefs(studentForm),
        ifstudentFound,
        getters_student_details,
        enterTransaction,
        fetchCalendar,
        getters_enrolunenrol,
        header,
        getters_abilities,
      };
    },
  });
</script>
